import * as React from "react";
import * as ReactDOM from "react-dom";

import { AuthProvider, useAuth } from "react-oidc-context";

const oidcConfig = {
    authority: process.env.APP_AUTHORITY,
    client_id: process.env.APP_CLIENT_ID || "customerportal",
    redirect_uri: process.env.APP_REDIRECT_URI,
    scope: 'openid email profile groups',
};

function App() {
    const auth = useAuth();

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    if (auth.isAuthenticated) {
        const url = new URL(location.href);
        url.searchParams.delete('code');
        url.searchParams.delete('state');
        if(location.href !== url.href) {
            location.assign(url.href);
        }
        return (
            <div>
                Hello {auth.user?.profile.sub}{" "}
                <br/>
                <br/>
                <textarea rows = "5" cols = "60" readonly>
                    {auth.user?.id_token}
                </textarea>
                <br/>
                <textarea rows = "5" cols = "60" readonly>
                    {auth.user?.access_token}
                </textarea>
                <br/>
                <button onClick={() => void auth.removeUser()}>
                    Log out
                </button>
            </div>
        );
    }

    return <button onClick={() => void auth.signinRedirect()}>Log in</button>;
    // return void auth.signinRedirect();
}

ReactDOM.render(
    <AuthProvider {...oidcConfig}>
        <App />
    </AuthProvider>,
    document.getElementById("root"),
);
